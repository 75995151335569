import * as React from "react";

function Filter(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M3 15.75V10.5M3 7.5V2.25M9 15.75V9M9 6V2.25M15 15.75V12M15 9V2.25M.75 10.5h4.5M6.75 6h4.5M12.75 12h4.5"
        stroke="#073937"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Filter;
