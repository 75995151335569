import React from "react";
import { Link } from "react-router-dom";

import Facebook from "../../Assets/Facebook";
import Linkedin from "../../Assets/Linkedin";
import Instagram from "../../Assets/Instagram";
import PlayStore from "../../Assets/PlayStore";
import Logo from "../../Assets/Logo";

export const Footer = () => (
  <footer className="pt-12 pb-4 lg:px-10 sm:px-4 bg-white border-t-4 border-t-primary">
    <div className="grid md:grid-cols-4 grid-rows-1 gap-2">
      <div className="flex flex-col gap-3">
        <Logo />
        <p className="text-[14px] font-normal font-helvetica">
          Sankapo is the premier online
          <br /> marketplace for all your buying and selling needs.
          <br /> Find great deals on a variety of products and services, all in
          one place.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p className=" text-lg font-normal font-helvetica">Buy</p>
        <Link
          to="/how-to-buy"
          className=" text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          How to Buy
        </Link>
      </div>
      <div className="flex flex-col gap-3">
        <p className=" text-lg font-normal font-helvetica">Contact</p>
        <p
          href="tel:+260777000723"
          className="text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          (+260) 777000723
        </p>
        <p
          href="mailto:we@sankapo.com"
          className=" text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          we@sankapo.com
        </p>
        <p className=" text-base font-light font-helvetica">
          Chainama Road Olympia <br />
          Lusaka, Zambia
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <p className=" text-lg font-normal font-helvetica">Information</p>
        <Link
          to="/terms-and-condition"
          className=" text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          Terms and condition
        </Link>
        <Link
          to="/faq"
          className=" text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          FAQ
        </Link>
        <Link
          to="/privacy-policy"
          className=" text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          Privacy policy
        </Link>
        <Link
          to="/vendor-agreements"
          className=" text-base font-light font-helvetica cursor-pointer hover:text-primary"
        >
          Vendor Agreements
        </Link>
      </div>
    </div>
    <div className="flex gap-4 border-t border-t-gray1 border-b border-b-gray1 p-2 w-44 mt-10">
      <a href="https://www.facebook.com/sankapo.buy.sell" target="_blank">
        <Facebook />
      </a>
      <a href="https://www.instagram.com/sankapo365" target="_blank">
        <Instagram />
      </a>
      <a
        href="https://www.linkedin.com/showcase/sankapo-add-it-like-it-get-it/?viewAsMember=true"
        target="_blank"
      >
        <Linkedin />
      </a>
      <a
        href=" https://play.google.com/store/apps/details?id=com.sankapo.online&hl=en&gl=US"
        className="ml-5"
      >
        <PlayStore />
      </a>
    </div>
    <div className="mt-8">
      <div className="w-full h-[1px] bg-[#dee0e04d]" />
      <p className=" font-helvetica font-normal mt-4 ">
        Ⓒ 2024 Zambian Market. Design by Skgraphicvision.DEV
      </p>
    </div>
  </footer>
);
