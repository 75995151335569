import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

//import components
import { Footer, Navbar, SearchBar } from "../../Components";

//assets
import HeroImage from "../../Assets/background-img2.png";
import BackgroundImage from "../../Assets/background-img3.png";

export default function AboutUsPage() {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      {/* hero section */}
      <div className="mt-20 relative overflow-x-hidden">
        <img
          className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
          src={HeroImage}
          alt="hero-img"
        />
        <div className="!absolute inset-0 flex flex-col justify-center items-center text-center gap-8 md:px-20 px-5">
          <p className="font-georgia md:text-[55px] sm:text-4xl text-white leading-snug">
            Die <br />
            Immobilienfinder
          </p>
          <p className="text-white font-helvetica md:text-[18px] text-[16px] text-md">
            Lorem ipsum dolor sit amet consectetur. Eleifend quam pulvinar nisi
            at
            <br /> pellentesque magna. Diam viverra fringilla diam sit cras
            lacus amet
            <br /> diam bibendum.
          </p>
        </div>
      </div>

      {/* about section */}
      <div>
        <div className="grid lg:grid-cols-2 sm:grid-rows-1 lg:mt-44 mt-20 ">
          <img
            className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
            src={BackgroundImage}
            alt="background-img"
          />

          <div className="flex flex-col justify-center lg:p-16 p-4 gap-4">
            <p className="lg:text-[44px] text-3xl font-georgia text-primary">
              Über Immointerest
            </p>
            <p className="text-16px] font-helvetica font-light">
              Lorem ipsum dolor sit amet consectetur. Volutpat aliquet ut
              consequat et ac elementum. Consequat volutpat malesuada ipsum
              aliquam morbi velit blandit volutpat. Euismod ut nunc eget tempus
              semper. Scelerisque porttitor sagittis suspendisse velit. Sit nisi
              proin odio adipiscing lacus elementum cursus urna. Vel erat ut
              egestas tellus id sagittis pretium egestas. Consectetur morbi
              neque pellentesque et ac vitae nullam. Interdum sed orci accumsan
              ac fringilla. Habitasse ipsum aliquam parturient neque non sed
              quisque eu nullam. Non fermentum ac nibh mollis.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
