import React from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import classNames from "classnames";
import Right from "../../Assets/Right";

export const TextInput = ({ name, label, multiline = false, ...rest }) => (
  <div className="flex flex-col gap-2">
    <label className="text-[16px] font-helvetica font-bold " htmlFor={name}>
      {label}
    </label>
    {multiline ? (
      <textarea
        className="border border-1 border-gray1 p-2 rounded-md   text-[14px] focus:outline-none"
        name={name}
        {...rest}
      />
    ) : (
      <input
        className="border border-1 border-gray1 p-2 rounded-md   text-[14px] focus:outline-none"
        name={name}
        {...rest}
      />
    )}
  </div>
);

export const Dropdown = ({ name, label, placeholder, data = [], ...rest }) => (
  <div className="flex flex-col gap-2">
    <label className="text-[16px] font-helvetica font-bold " htmlFor={name}>
      {label}
    </label>
    <select
      className="border border-1 border-gray1 p-2 rounded-sm  text-[14px] focus:outline-none font-helvetica"
      name={name}
      {...rest}
    >
      <option
        value=""
        selected
        disabled
        className="text-primary font-helvetica"
      >
        {placeholder}
      </option>
      {data.map((item, idx) => (
        <option key={idx} value={item.value} className="text-primary">
          {item.label}
        </option>
      ))}
    </select>
  </div>
);

export const Checkbox = ({ label, checked, name, ...rest }) => (
  <div className="flex gap-2 items-center">
    <a
      className={classNames(
        "w-[20px] h-[20px] flex justify-center items-center rounded",
        checked ? "bg-primary" : "border border-1 border-gray"
      )}
      name={name}
      {...rest}
    >
      {checked && <Right />}
    </a>
    <label htmlFor={name} className="text-[16px] font-helvetica font-bold">
      {label}
    </label>
  </div>
);

export const DropdownInput = ({
  name,
  label,
  data = [],
  selectedValue,
  placeholder,
  value,
  required = false,
  onChangeText,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <label
        className="text-[16px] font-helvetica font-bold text-primary"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="border border-1 border-gray1 p-2 rounded-sm  flex gap-2">
        <select
          className="text-primary font-bold bg-background placeholder:text-primary text-[14px] focus:outline-none"
          name={name}
        >
          <option selected className="text-primary">
            {selectedValue}
          </option>
          {data.map((item, idx) => (
            <option key={idx} value={item.value} className="text-primary">
              {item.label}
            </option>
          ))}
        </select>
        <input
          className=" placeholder:text-primary w-full bg-background text-[14px] focus:outline-none"
          name={name}
          type="number"
          placeholder={placeholder}
          onChange={onChangeText}
          value={value}
          required={required}
        />
      </div>
    </div>
  );
};

export const RangeSliderComponent = ({ labels, name, value, ...rest }) => {
  return (
    <div className="flex flex-col gap-4">
      <label className="text-[16px] font-helvetica font-bold text-primary">
        {name}
      </label>
      <RangeSlider value={value} {...rest} />
      <div className="flex flex-row justify-between">
        <p className="text-sm font-helvetica font-medium">
          {labels[0]} {value[0]}
        </p>
        <p className="text-sm font-helvetica font-medium">
          {labels[1]} {value[1]}
        </p>
      </div>
    </div>
  );
};
