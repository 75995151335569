import React, { useEffect } from "react";
import { Footer, Navbar } from "../../../Components";

export default function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] lg:px-10 sm:px-4 mb-10 flex flex-col gap-4 ">
        <p className="text-2xl font-helvetica font-semibold text-center">
          Terms of Services and Conditions
        </p>

        <p className="font-helvetica text-gray">
          Welcome to Sankapo.com, a platform for buying and selling items online
          ("Site"). By accessing or using the Site, you agree to be bound by
          these terms of service ("Terms"). If you do not agree to these Terms,
          do not use the Site.
        </p>

        <p className="text-lg font-helvetica font-semibold">Introduction</p>
        <p className="font-helvetica text-gray">
          These Terms apply to all users of the Site, including users who are
          also contributors of content, information, and other materials or
          services on the Site.
        </p>
        <p className="font-helvetica text-gray">
          The Site may contain links to third party websites that are not owned
          or controlled by Sankapo.com. Sankapo.com has no control over, and
          assumes no responsibility for, the content, privacy policies, or
          practices of any third party websites. By using the Site, you
          expressly relieve Sankapo.com from any and all liability arising from
          your use of any third party website.
        </p>

        <p className="text-lg font-helvetica font-semibold">Use of the Site</p>
        <p className="font-helvetica text-gray">
          You may use the Site only for lawful purposes and in accordance with
          these Terms. You agree not to use the Site:
        </p>

        <p className="font-helvetica text-gray">
          In any way that violates any applicable federal, state, local, or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries).
        </p>

        <p className="font-helvetica text-gray">
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information, or otherwise.
        </p>

        <p className="font-helvetica text-gray">
          To transmit, or procure the sending of, any advertising or promotional
          material, including any "junk mail," "chain letter," "spam," or any
          other similar solicitation.
        </p>

        <p className="font-helvetica text-gray">
          To impersonate or attempt to impersonate Sankapo.com, a Sankapo.com
          employee, another user, or any other person or entity (including,
          without limitation, by using e-mail addresses or screen names
          associated with any of the foregoing).
        </p>

        <p className="font-helvetica text-gray">
          To engage in any other conduct that restricts or inhibits anyone's use
          or enjoyment of the Site, or which, as determined by us, may harm
          Sankapo.com or users of the Site or expose them to liability.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Additionally, you agree not to:
        </p>

        <p className="font-helvetica text-gray">
          Use the Site in any manner that could disable, overburden, damage, or
          impair the Site or interfere with any other party's use of the Site,
          including their ability to engage in real time activities through the
          Site.
        </p>

        <p className="font-helvetica text-gray">
          Use any robot, spider, or other automatic device, process, or means to
          access the Site for any purpose, including monitoring or copying any
          of the material on the Site.
        </p>

        <p className="font-helvetica text-gray">
          Use any manual process to monitor or copy any of the material on the
          Site or for any other unauthorized purpose without our prior written
          consent.
        </p>

        <p className="font-helvetica text-gray">
          Use any device, software, or routine that interferes with the proper
          working of the Site.
        </p>

        <p className="font-helvetica text-gray">
          Introduce any viruses, trojan horses, worms, logic bombs, or other
          material which is malicious or technologically harmful.
        </p>

        <p className="font-helvetica text-gray">
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of the Site, the server on which the Site is stored,
          or any server, computer, or database connected to the Site.
        </p>

        <p className="font-helvetica text-gray">
          Attack the Site via a denial-of-service attack or a distributed
          denial-of-service attack.
        </p>

        <p className="font-helvetica text-gray">
          Otherwise attempt to interfere with the proper working of the Site.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          User Contributions
        </p>
        <p className="font-helvetica text-gray">
          The Site may contain message boards, chat rooms, personal web pages,
          or other interactive features (collectively, "Interactive Services")
          that allow users to post, submit, publish, display, or transmit to
          other users or other persons (hereinafter, "post") content or
          materials (collectively, "User Contributions") on or through the Site.
        </p>

        <p className="font-helvetica text-gray">
          All User Contributions must comply with the content standards set out
          in these Terms. Any User Contribution you post to the site will be
          considered non-confidential and non-proprietary. By providing any User
          Contribution on the Site, you grant us and our affiliates and service
          providers, and each of their and our respective licensees, successors,
          and assigns the right to use, reproduce, modify, perform, display,
          distribute, and otherwise disclose to third parties any such material
          for any purpose.
        </p>
        <p className="text-lg font-helvetica font-semibold">
          You represent and warrant that:
        </p>

        <p className="font-helvetica text-gray">
          You own or control all rights in and to the User Contributions and
          have the right to grant the license granted above to us and our
          affiliates and service providers, and each of their and our respective
          licensees, successors, and assigns.
        </p>

        <p className="font-helvetica text-gray">
          All of your User Contributions do and will comply with these Terms.
        </p>

        <p className="font-helvetica text-gray">
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not Sankapo.com, have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness.
        </p>

        <p className="font-helvetica text-gray">
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user of
          the Site.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          We have the right to
        </p>

        <p className="font-helvetica text-gray">
          Remove or refuse to post any User Contributions for any or no reason
          in our sole discretion.
        </p>

        <p className="font-helvetica text-gray">
          Take any action with respect to any User Contribution that we deem
          necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates these Terms, infringes
          any intellectual property right or other right of any person or
          entity, threatens the personal safety of users of the Site or the
          public, or could create liability for Sankapo.com.
        </p>

        <p className="font-helvetica text-gray">
          Disclose your identity or other information about you to any third
          party who claims that material posted by you violates their rights,
          including their intellectual property rights or their right to
          privacy.
        </p>

        <p className="font-helvetica text-gray">
          Take appropriate legal action, including without limitation, referral
          to law enforcement, for any illegal or unauthorized use of the Site.
        </p>

        <p className="font-helvetica text-gray">
          Terminate or suspend your access to all or part of the Site for any or
          no reason, including without limitation, any violation of these Terms.
        </p>

        <p className="font-helvetica text-gray">
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Site. YOU WAIVE AND HOLD
          HARMLESS SANKAPO.COM AND ITS AFFILIATES, LICENSEES, AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
          THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND
          FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER
          SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Intellectual Property
        </p>
        <p className="font-helvetica text-gray">
          The Site and its original content, features, and functionality are and
          will remain the exclusive property of Sankapo.com and its licensors.
          The Site is protected by copyright, trademark, and other laws of both
          the United States and foreign countries. Our trademarks and trade
          dress may not be used in connection with any product or service
          without the prior written consent of Sankapo.com.
        </p>

        <p className="text-lg font-helvetica font-semibold">Termination</p>
        <p className="font-helvetica text-gray">
          We may terminate your access to the Site, without cause or notice,
          which may result in the forfeiture and destruction of all information
          associated with you. All provisions of these Terms that by their
          nature should survive termination shall survive termination,
          including, without limitation, ownership provisions, warranty
          disclaimers, indemnity, and limitations of liability.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Disclaimer of Warranties
        </p>
        <p className="font-helvetica text-gray">
          The Site is provided on an "as is" and "as available" basis.
          Sankapo.com makes no representations or warranties of any kind,
          express or implied, as to the operation of the Site or the
          information, content, materials, or products included on the Site. To
          the full extent permissible by law, Sankapo.com disclaims all
          warranties, express or implied, including, but not limited to, implied
          warranties of merchantability and fitness for a particular purpose.
          Sankapo.com will not be liable for any damages of any kind arising
          from the use of the Site, including, but not limited to, direct,
          indirect, incidental, punitive, and consequential damages.
        </p>
        <p className="text-lg font-helvetica font-semibold">
          Limitation of Liability
        </p>
        <p className="font-helvetica text-gray">
          Sankapo.com will not be liable for any damages of any kind arising
          from the use of the Site, including, but not limited to, direct,
          indirect, incidental, punitive, and consequential damages.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Accuracy of Information
        </p>
        <p className="font-helvetica text-gray">
          We are not responsible if information made available on this Site is
          not accurate, complete, or current. The material on this Site is
          provided for general information only and should not be relied upon or
          used as the sole basis for making decisions without consulting
          primary, more accurate, more complete, or more timely sources of
          information. Any reliance on the material on this Site is at your own
          risk.
        </p>

        <p className="font-helvetica text-gray">
          This Site may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your
          reference only. We reserve the right to modify the contents of this
          Site at any time, but we have no obligation to update any information
          on our Site. You agree that it is your responsibility to monitor
          changes to our Site.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Modifications to the Site
        </p>
        <p className="font-helvetica text-gray">
          We reserve the right to modify or discontinue, temporarily or
          permanently, the Site or any part of it, with or without notice, and
          without liability to you or any third party.
        </p>

        <p className="text-lg font-helvetica font-semibold">Governing Law</p>
        <p className="font-helvetica text-gray">
          These Terms and your use of the Site will be governed by and construed
          in accordance with the laws of the State of California, without giving
          effect to any principles of conflicts of law.
        </p>

        <p className="text-lg font-helvetica font-semibold">Disputes</p>
        <p className="font-helvetica text-gray">
          Any dispute arising out of or relating to these Terms or the Site
          shall be resolved through binding arbitration in accordance with the
          commercial arbitration rules of the American Arbitration Association.
          Any such arbitration shall be conducted in the English language in San
          Francisco, California. The award of the arbitration shall be final and
          binding on the parties, and judgment may be entered thereon in any
          court of competent jurisdiction.
        </p>

        <p className="text-lg font-helvetica font-semibold">Miscellaneous</p>
        <p className="font-helvetica text-gray">
          These Terms constitute the entire agreement between you and
          Sankapo.com and govern your use of the Site, superseding any prior
          agreements between you and Sankapo.com (including, but not limited to,
          any prior versions of the Terms).
        </p>

        <p className="font-helvetica text-gray">
          You agree that these Terms, together with any additional terms and
          conditions that may be provided by Sankapo.com from time to time, are
          the complete and exclusive statement of the mutual understanding of
          the parties and supersede and cancel all previous written and oral
          agreements, communications, and other understandings relating to the
          subject matter of these Terms.
        </p>

        <p className="font-helvetica text-gray">
          If any provision of these Terms is found to be invalid or
          unenforceable, then that provision shall be enforced to the maximum
          extent possible, and the remaining provisions shall remain in full
          force and effect.
        </p>

        <p className="font-helvetica text-gray">
          The failure of Sankapo.com to exercise or enforce any right or
          provision of these Terms shall not constitute a waiver of such right
          or provision.
        </p>
      </div>

      <Footer />
    </>
  );
}
