import { Routes, Route, RouterProvider } from "react-router-dom";

import Homepage from "./Pages/Home";
import ProductsPage from "./Pages/Products";
import AboutUsPage from "./Pages/AboutUs";
import LoginPage from "./Pages/Auth/Login";
import CategoryPage from "./Pages/Category";
import PrivacyPage from "./Pages/Information/Privacy";
import FaqPage from "./Pages/Information/Faq";
import TermsPage from "./Pages/Information/Terms";
import VendorAgreementPage from "./Pages/Information/Vendor";
import HowToPage from "./Pages/Information/HowTo";
import AddProductsPage from "./Pages/AddProducts";
import NotFoundPage from "./Pages";

//register
import StepOnePage from "./Pages/Auth/Register/Step1";
import StepTwoPage from "./Pages/Auth/Register/Step2";
import StepThreePage from "./Pages/Auth/Register/Step3";
import PaymentPage from "./Pages/Auth/Register/Payment";
import StorePage from "./Pages/Auth/Register/Store";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      {/* <Route path="/detail/:city" element={<DetailPage />} /> */}
      <Route path="/filter/:category" element={<ProductsPage />} />
      <Route
        path="/create-advertisement/:category"
        element={<AddProductsPage />}
      />
      <Route path="/about_us" element={<AboutUsPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/add-account" element={<StepTwoPage />} />
      <Route path="/account-type" element={<StepOnePage />} />
      <Route path="/register-account" element={<StepThreePage />} />
      <Route path="/store" element={<StorePage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/category" element={<CategoryPage />} />
      <Route path="/privacy-policy" element={<PrivacyPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/terms-and-condition" element={<TermsPage />} />
      <Route path="/vendor-agreements" element={<VendorAgreementPage />} />
      <Route path="/how-to-buy" element={<HowToPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
