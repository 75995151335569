import React, { createContext, useContext, useState, useEffect } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [regData, setRegData] = useState({
    accountType: "Free",
    full_name: null,
    email: null,
    phone_number: null,
    password: null,
    nrc: null,
    province: "test",
    street_address: null,
    city: "test",
    postal_code: "test",
    image: null,
    store_location: null,
    store_name: null,
    store_logo: null,
    account_cost: 0,
  });

  const [advertiseData, setAdvertiseData] = useState({
    title: null,
    description: null,
    condition: null,
    price: null,
    whatsapp_number: null,
    images: [],
    video: null,
    price: null,
    landPhone: null,
    email: null,
    sellerName: null,
    province: null,
    city: null,
    town: null,
    owner: null,
    categoryId: null,
    address: null,
  });

  const clearAdvertiseData = () => {
    setAdvertiseData({
      title: null,
      description: null,
      condition: null,
      price: null,
      images: [],
      video: null,
      whatsapp_number: null,
      landPhone: null,
      email: null,
      sellerName: null,
      province: null,
      city: null,
      town: null,
      owner: null,
      categoryId: null,
      address: null,
    });
  };

  return (
    <AppContext.Provider
      value={{
        regData,
        setRegData,
        setAdvertiseData,
        advertiseData,
        clearAdvertiseData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
