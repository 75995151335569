import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthHeaders } from "./baseQueries";

export const advertiseApi = createApi({
  reducerPath: "advertiseApi",
  baseQuery: baseQueryWithAuthHeaders,
  tagTypes: ["Advertise"],
  endpoints: (build) => ({
    getAllAdvertise: build.query({
      query: () => ({
        url: "/product/approved",
        method: "GET",
      }),
      providesTags: ["Advertise"],
      transformResponse: (response) => response.data,
    }),
    getAdsByCategory: build.query({
      query: ({ id }) => ({
        url: `/product/searchbycategory/${id}`,
        method: "GET",
      }),
      providesTags: ["Advertise"],
      transformResponse: (response) => response.data,
    }),

    // getAllAdvertiseByCity: build.query({
    //   query: ({ city }) => ({
    //     url: `/advertise/filter`,
    //     method: "GET",
    //     params: {
    //       city,
    //     },
    //   }),
    //   providesTags: ["Advertise"],
    //   transformResponse: (response) => response.data,
    // }),
    // getFilterAdvertise: build.query({
    //   query: ({
    //     city,
    //     minArea,
    //     rooms,
    //     minPrice,
    //     maxPrice,
    //     minConstructionYear,
    //     maxConstructionYear,
    //   }) => ({
    //     url: `/advertise/filter`,
    //     method: "GET",
    //     params: {
    //       city,
    //       minArea,
    //       rooms,
    //       minPrice,
    //       maxPrice,
    //       minConstructionYear,
    //       maxConstructionYear,
    //     },
    //   }),
    //   providesTags: ["Advertise"],
    //   transformResponse: (response) => response.data,
    // }),
    addAdvertise: build.mutation({
      query: (data) => ({
        url: `/product/store`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Advertise"],
    }),
  }),
});

export const {
  useGetAllAdvertiseQuery,
  useGetAdsByCategoryQuery,
  useAddAdvertiseMutation,
} = advertiseApi;
