export const getFormattedBalance = (balance) => {
  // Check if balance is a valid number
  if (isNaN(balance)) {
    return "0.00"; // Return default value or handle the error as needed
  }
  const formattedBalance = `${parseFloat(
    balance.toString().split(".")[0]
  ).toLocaleString()}.${
    balance.toString().split(".")[1] ? balance.toString().split(".")[1] : "00"
  }`;

  return formattedBalance;
};
