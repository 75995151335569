import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { advertiseApi } from "../Services/AdvertiseService";
import { categoryApi } from "../Services/CategoryService";
import { commonApi } from "../Services/CommonService";
import { authApi } from "../Services/AuthService";
import { userApi } from "../Services/UserService";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice

    [advertiseApi.reducerPath]: advertiseApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(advertiseApi.middleware)
      .concat(categoryApi.middleware)
      .concat(commonApi.middleware)
      .concat(authApi.middleware)
      .concat(userApi.middleware),
});

setupListeners(store.dispatch);
