import React from "react";
import { useNavigate } from "react-router-dom";

import { Constant } from "../../Constant";
import { useApp } from "../../Context";

//import assets
import Magnifying from "../../Assets/Magnifying";
import DollarSign from "../../Assets/DollarSign";
import Box from "../../Assets/Box";
import Filter from "../../Assets/Filter";

export const SearchBar = ({ handleShowFilter, categories }) => {
  const navigate = useNavigate();
  const { filters, setFilters } = useApp();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFilter = () => {
    navigate("/filter");
  };

  return (
    <div className="flex w-full bg-background h-16 rounded-full items-center px-6 py-2 md:gap-5 gap-2">
      <div className="flex lg:gap-x-5 gap-x-1 items-center ">
        <div className="md:block sm:hidden">
          <Box />
        </div>
        {/* <select
          name="rooms"
          value={filters.rooms}
          onChange={handleInputChange}
          className=" w-18 font-light font-helvetica  bg-inherit focus:outline-none"
        >
          <option value="" selected disabled className="text-blue-gray-50">
            Category
          </option>
          {categories?.map((item, idx) => (
            <option key={idx} value={item.value} className="text-primary">
              {item.category}
            </option>
          ))}
        </select> */}
      </div>
      <div className="flex gap-x-2 items-center">
        <input
          name="city"
          onChange={handleInputChange}
          placeholder="What do you want to buy?"
          className="text-primary font-light font-helvetica bg-background focus:outline-none lg:w-52 w-44"
        />
        <div className="md:block sm:hidden">
          <Magnifying />
        </div>
      </div>
    </div>
  );
};
