import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import {
  DetailCard,
  Footer,
  FilterModal,
  Navbar,
  PreviewModal,
  SearchBar,
  Loader,
  Button,
} from "../../Components";
import { useGetAdsByCategoryQuery } from "../../Services/AdvertiseService";
import { useApp } from "../../Context";
import { useGetCategoryQuery } from "../../Services/CategoryService";

export default function ProductsPage() {
  const { category } = useParams();

  //services
  const { data: advertises, isLoading } = useGetAdsByCategoryQuery({
    id: category,
  });
  const { data: categories, isLoading: loadingCategory } =
    useGetCategoryQuery();

  const [next, setNext] = useState(8);
  const [showFilter, setShowFilter] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedAdData, setSelectedAdData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShowFilter = () => setShowFilter((prevState) => !prevState);

  const handleShowPreview = (adData) => {
    setSelectedAdData(adData);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setSelectedAdData(null);
    setShowPreview(false);
  };

  const showMoreProducts = () => {
    setNext((prevState) => prevState + 8);
  };

  return (
    <>
      <Navbar />
      {isLoading && <Loader isLoading={isLoading} text="Loading..." />}

      <div className="bg-primary mt-[88px] p-4  flex items-center justify-center">
        <div>
          <SearchBar categories={categories} />
        </div>
      </div>

      <p className="text-[20px] font-helvetica font-bold lg:m-12 sm:m-5">
        {advertises?.length} results
      </p>
      <div className="lg:px-12 sm:px-5  flex flex-col gap-4 mb-4">
        {advertises?.slice(0, next).map((item, idx) => (
          <DetailCard
            key={idx}
            data={item}
            onClick={() => handleShowPreview(item)}
          />
        ))}
      </div>

      {advertises?.length > 8 && (
        <div className="flex justify-center mt-[41px] mb-10">
          <Button
            title="See More"
            variant="primary"
            onClick={showMoreProducts}
          />
        </div>
      )}

      <Footer />

      {selectedAdData !== null && (
        <PreviewModal
          open={showPreview}
          handleClose={handleClosePreview}
          data={selectedAdData}
        />
      )}
    </>
  );
}
