import React, { useEffect } from "react";
import { Footer, Navbar } from "../../../Components";

export default function PrivacyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] lg:px-10 sm:px-4 mb-10 flex flex-col gap-4 ">
        <p className="text-2xl font-helvetica font-semibold text-center">
          Privacy Policy
        </p>
        <p className="font-helvetica text-gray">
          Welcome to Sankapo.com (the "Site"), a buy-and-sell website owned and
          operated by Innovage Investment Ltd. Your privacy is important to us,
          and we want you to understand our policies and practices with respect
          to the collection, use, and disclosure of your personal information.
          This privacy policy applies to information we collect when you use the
          Site and describes the choices available to you regarding our use of
          your personal information and how you can access and update this
          information. By visiting the Site, you accept the practices described
          in this privacy policy.
        </p>
        <div className="w-full h-[1px] bg-[#d8d8d8] my-4" />
        <p className="text-lg font-helvetica font-semibold">
          Information we collect
        </p>
        <p className="font-helvetica text-gray">
          We may collect the following information about you when you use the
          Site:
        </p>

        <ul className="font-helvetica text-gray list-disc pl-5">
          <li>
            Personal Information :{" "}
            <span>
              We collect personal information that you voluntarily provide to us
              when you use the Site, such as your name, email address, phone
              number, and postal address. We may also collect other personal
              information that you choose to provide, such as your gender and
              date of birth.
            </span>
          </li>
          <li>
            Transaction Information :{" "}
            <span>
              We collect information about the items you buy and sell on the
              Site, such as the item description, price, and shipping
              information.
            </span>
          </li>
          <li>
            Usage Information :{" "}
            <span>
              We collect information about how you use the Site, including the
              pages you visit, the features you use, and the searches you
              conduct. We may also collect information about the device you use
              to access the Site, including the device's IP address, browser
              type, and operating system.
            </span>
          </li>
        </ul>

        <p className="text-lg font-helvetica font-semibold">
          How we use your information
        </p>

        <p className="font-helvetica text-gray">
          We use the information we collect from you to operate and improve the
          Site and to provide you with the services you request. Specifically,
          we use your information for the following purposes:
        </p>

        <ul className="font-helvetica text-gray list-disc pl-5">
          <li>
            To facilitate your transactions on the Site :{" "}
            <span>
              We use your personal and transaction information to process your
              orders, communicate with you about your transactions, and provide
              you with customer support.
            </span>
          </li>
          <li>
            To personalize your experience :{" "}
            <span>
              We use your personal information to provide you with personalized
              recommendations and to tailor the content and features of the Site
              to your interests
            </span>{" "}
          </li>
          <li>
            To improve the Site :{" "}
            <span>
              We use your usage information to understand how people use the
              Site and to identify areas for improvement. We may also use your
              personal information to contact you for research or feedback
              purposes.
            </span>{" "}
          </li>
        </ul>

        <p className="text-lg font-helvetica font-semibold">
          Sharing your information
        </p>

        <p className="font-helvetica text-gray">
          We may share your information with third parties for the following
          purposes:
        </p>

        <ul className="font-helvetica text-gray list-disc pl-5">
          <li>
            Service Providers :{" "}
            <span>
              We may share your information with service providers who perform
              services on our behalf, such as payment processors, shipping
              companies, and website hosting providers. These service providers
              are required to use your information only as necessary to perform
              their services for us.
            </span>
          </li>
          <li>
            Business Partners :{" "}
            <span>
              We may share your information with business partners who jointly
              offer products or services with us. These partners will use your
              information only as necessary to offer the joint products or
              services.
            </span>{" "}
          </li>
          <li>
            Legal Disclosure :{" "}
            <span>
              We may disclose your information to third parties if we believe in
              good faith that such disclosure is necessary to comply with the
              law, enforce our policies, or protect the rights, property, or
              safety of the Site or others.
            </span>{" "}
          </li>
        </ul>

        <p className="text-lg font-helvetica font-semibold">Your Choices</p>
        <p className="font-helvetica text-gray">
          You have the following choices regarding the collection, use, and
          sharing of your personal information:
        </p>
        <ul className="font-helvetica text-gray list-disc pl-5">
          <li>
            {" "}
            Opting Out of Marketing Communications:{" "}
            <span>
              {" "}
              You may opt out of receiving marketing communications from us by
              following the unsubscribe instructions provided in the
              communications or by contacting us at [Email Address]. Please note
              that even if you opt out of marketing communications, we may still
              send you non-marketing communications, such as order confirmation
              emails or customer service inquiries.
            </span>
          </li>
          <li>
            Accessing and Updating Your Personal Information:{" "}
            <span>
              You may access and update your personal information at any time by
              logging into your account on the Site. If you are unable to access
              or update your personal information, please contact us at [Email
              Address] and we will assist
            </span>
          </li>
        </ul>

        <p className="text-lg font-helvetica font-semibold">Data Security</p>
        <p className="font-helvetica text-gray">
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure, or destruction of
          your personal information. These measures include internal reviews of
          our data collection, storage, and processing practices and security
          measures, as well as physical security measures to protect against
          unauthorized access to systems where we store personal information.
        </p>

        <p className="text-lg font-helvetica font-semibold">Data Retention</p>
        <p className="font-helvetica text-gray">
          We will retain your personal information for as long as necessary to
          provide you with the services you request or as required by law. If
          you no longer wish to use the Site or request that we no longer use
          your personal information, you may request that we delete your
          personal information by contacting us at we@sankapo.com. Please note
          that we may be required to retain certain information for
          recordkeeping purposes or to complete transactions that you began
          prior to requesting the deletion of your personal information
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Children's Privacy
        </p>
        <p className="font-helvetica text-gray">
          The Site is not intended for children under the age of 13. We do not
          knowingly collect personal information from children under the age of
          13. If you are a parent or guardian and you become aware that your
          child has provided us with personal information, please contact us at
          we@sankapo.com.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Changes to This Privacy Policy
        </p>
        <p className="font-helvetica text-gray">
          We may update this Privacy Policy from time to time to reflect changes
          to our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review the Privacy Policy whenever you
          access the Site to stay informed about our collection, use, and
          disclosure of your personal information
        </p>

        <p className="text-lg font-helvetica font-semibold">Contact Us</p>
        <p className="font-helvetica text-gray">
          If you have any questions or concerns about this Privacy Policy or our
          collection, use, and disclosure of your personal information, please
          contact us at we@sankapo.com.
        </p>
      </div>
      <Footer />
    </>
  );
}
