import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: publicBaseQuery,
  tagTypes: ["Common"],
  endpoints: (build) => ({
    getBanners: build.query({
      query: () => ({
        url: "/banner/approved",
        method: "GET",
      }),
      providesTags: ["Common"],
      transformResponse: (response) => response.data,
    }),
    getPaymentDetails: build.query({
      query: () => ({
        url: "/payment/list",
        method: "GET",
      }),
      providesTags: ["Common"],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetBannersQuery, useGetPaymentDetailsQuery } = commonApi;
