import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useMask } from "@react-input/mask";

import { useApp } from "../../../../Context";

import LogoImg from "../../../../Assets/LOGO.png";
import Logo from "../../../../Assets/Logo";
import { Button } from "../../../../Components";

import AirtelIcon from "../../../../Assets/AirtelIcon";
import MTNIcon from "../../../../Assets/MTNIcon";
import ZamtelIcon from "../../../../Assets/ZamtelIcon";
import { Checkbox } from "../../../../Components/FormElements";
import { Alert } from "../../../../Components";

export default function PaymentPage() {
  const navigate = useNavigate();

  const { regData, setRegData } = useApp();
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [error, setError] = useState({
    type: "",
    text: "",
    isShow: false,
  });

  const inputRefPhone = useMask({
    mask: "+260 (__) ___-__-__",
    replacement: { _: /\d/ },
  });

  const handleNavigate = () => {
    if (paymentMethod) {
      setStep(2);
    } else
      setError({
        type: "Warning",
        text: "Please select your payment method!",
        isShow: true,
      });
  };

  return (
    <>
      <div className="bg-secondary h-screen w-screen relative">
        <div className="absolute top-2 left-2">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="bg-white shadow-md rounded-xl lg:w-[470px] w-[350px] px-5 py-10">
            <div className="flex flex-col items-center gap-2 ">
              <Logo />
              <p className="lg:text-lg text-sm font-helvetica">Payment</p>
              {/* <p className="lg:text-lg text-sm font-helvetica text-gray text-center">
                Let's get started by personalizing your experience.
              </p> */}
            </div>
            <div className="mt-3">
              <p className="text-lg text-gray font-helvetica">Bill summery</p>
              <div className="grid grid-cols-2 bg-white shadow-md rounded-md p-2 gap-4 mt-3">
                <p className="text-sm text-gray font-helvetica">Details</p>
                <p className="text-sm font-helvetica">Registration Payment</p>
                <p className="text-sm text-gray font-helvetica">Total Cost</p>
                <p className="text-sm font-helvetica text-secondary">
                  K {regData.account_cost}
                </p>
              </div>
            </div>
            {step === 1 ? (
              <div className="mt-3">
                <p className="text-lg text-gray font-helvetica">
                  Payment Method
                </p>
                <div className="flex flex-col gap-4 mt-3 bg-white shadow-md rounded-md p-2">
                  <a className="flex gap-2 items-center justify-between text-sm text-gray font-helvetica">
                    <span className="flex items-center gap-2">
                      <Checkbox
                        onClick={() => setPaymentMethod("airtel")}
                        checked={paymentMethod === "airtel"}
                      />{" "}
                      Airtel Money
                    </span>
                    <AirtelIcon />
                  </a>
                  <a className="flex gap-2 items-center justify-between text-sm text-gray font-helvetica">
                    <span className="flex items-center gap-2">
                      <Checkbox
                        onClick={() => setPaymentMethod("mtn")}
                        checked={paymentMethod === "mtn"}
                      />{" "}
                      MTN Money
                    </span>
                    <MTNIcon />
                  </a>
                  <a className="flex gap-2 items-center justify-between text-sm text-gray font-helvetica">
                    <span className="flex items-center gap-2">
                      <Checkbox
                        onClick={() => setPaymentMethod("zamtel")}
                        checked={paymentMethod === "zamtel"}
                      />{" "}
                      Zamtel Money
                    </span>

                    <ZamtelIcon />
                  </a>
                  <a className="flex gap-2 items-center justify-between text-sm text-gray font-helvetica">
                    <span className="flex items-center gap-2">
                      <Checkbox
                        onClick={() => setPaymentMethod("spenn")}
                        checked={paymentMethod === "spenn"}
                      />{" "}
                      SPENN
                    </span>
                    <ZamtelIcon />
                  </a>
                </div>
              </div>
            ) : step === 2 ? (
              <div className="mt-3 flex flex-col gap-4">
                <p className="text-[16px] font-helvetica text-gray">
                  Enter your phone number to complete your mobile money payment.
                </p>
                <input
                  ref={inputRefPhone}
                  className="border border-1 border-gray1 p-2 rounded-md w-full   text-[14px] focus:outline-none"
                  type="text"
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                />
                <p className="text-xs text-blue-gray-400">
                  Your phone number is safe and will only be used for payment
                  verification<span className="text-red-400">*</span>
                </p>
                <span className="flex gap-2 text-sm font-helvetica text-gray">
                  <Checkbox /> <p>I agree to Sankapo’s </p>
                  <Link to="/terms-and-condition" className="text-primary">
                    Terms & Conditions
                  </Link>
                </span>
              </div>
            ) : null}

            <div className="mt-3">
              <Button
                title="Continue"
                full
                variant="primary"
                onClick={handleNavigate}
              />
              {/* <span className="text-gray font-helvetica">
                Have you an account?{" "}
                <Link to="/login" className="text-primary font-bold">
                  Login here
                </Link>
              </span> */}
            </div>
          </div>
        </div>
      </div>
      <Alert
        isPopup={error.isShow}
        handlePopup={() => setError({ isShow: false })}
        type={error.type}
        text={error.text}
      />
    </>
  );
}
