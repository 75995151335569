import React from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { useApp } from "../../../../Context";

import LogoImg from "../../../../Assets/LOGO.png";
import Logo from "../../../../Assets/Logo";
import { Button } from "../../../../Components";

import { UserCircleIcon, StarIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useGetPaymentDetailsQuery } from "../../../../Services/CommonService";
import { Spinner } from "@material-tailwind/react";

export default function StepOnePage() {
  const navigate = useNavigate();
  const { regData, setRegData } = useApp();

  const { data, isLoading } = useGetPaymentDetailsQuery();

  const handleNavigate = () => {
    setRegData((prevState) => ({
      ...prevState,
      account_cost: data.business_acc_charge,
    }));
    navigate("/add-account");
  };

  return (
    <>
      <div className="bg-secondary h-screen w-screen relative">
        <div className="absolute top-2 left-2">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="bg-white shadow-md rounded-xl lg:w-[470px] w-[350px] px-5 py-10">
            <div className="flex flex-col items-center gap-2 ">
              <Logo />
              <p className="lg:text-lg text-sm font-helvetica">Sign up</p>
              <p className="lg:text-lg text-sm font-helvetica text-gray text-center">
                Let's get started by personalizing your experience.
              </p>
            </div>
            <div className="flex gap-5 justify-center mt-3">
              <a
                className={classNames(
                  "flex flex-col gap-1 bg-white shadow-md w-[125px] p-2 rounded-md",
                  regData.accountType === "Free" && "border border-primary"
                )}
                onClick={() => setRegData({ accountType: "Free" })}
              >
                <div className="w-12 h-12 bg-lightOrange flex flex-col justify-center items-center rounded-xl">
                  <UserCircleIcon className="w-8 h-8 text-primary" />
                </div>
                <p className="text-sm font-helvetica text-gray">Individual</p>
                <p className="text-lg font-helvetica font-semibold">
                  Free <span className="text-xs font-light">Limited</span>
                </p>
              </a>
              <a
                className={classNames(
                  "flex flex-col gap-1 bg-white shadow-md w-[125px] p-2 rounded-md",
                  regData.accountType === "Paid" && "border border-primary"
                )}
                onClick={() => setRegData({ accountType: "Paid" })}
              >
                <div className="w-12 h-12 bg-lightOrange flex flex-col justify-center items-center rounded-xl">
                  <StarIcon className="w-8 h-8 text-primary" />
                </div>
                <p className="text-sm font-helvetica text-gray">Business</p>
                <p className="text-lg font-helvetica font-semibold text-secondary">
                  {isLoading ? (
                    <Spinner className="h-4 w-4" color="blue" />
                  ) : (
                    `K ${data.business_acc_charge}`
                  )}{" "}
                  <span className="text-xs font-light">Per month</span>
                </p>
              </a>
            </div>
            <div className="mt-3 p-2">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    <th className="text-[16px] text-blue-gray-800 font-normal font-helvetica">
                      Features
                    </th>
                    <th className="text-[16px] text-blue-gray-800 font-normal font-helvetica">
                      Individual
                    </th>
                    <th className="text-[16px] text-blue-gray-800 font-normal font-helvetica">
                      Business
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-sm text-gray font-normal font-helvetica">
                      Setup Store
                    </td>
                    <td className="text-3xl">
                      <MinusIcon className="w-4 h-4 " />
                    </td>
                    <td>
                      <CheckCircleIcon className="w-4 h-4 text-primary" />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm text-gray font-normal font-helvetica">
                      Upload Photos
                    </td>
                    <td>
                      <CheckCircleIcon className="w-4 h-4 text-primary" />
                    </td>
                    <td>
                      <CheckCircleIcon className="w-4 h-4 text-primary" />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm text-gray font-normal font-helvetica">
                      Upload Videos
                    </td>
                    <td className="text-3xl">
                      <MinusIcon className="w-4 h-4 " />
                    </td>
                    <td>
                      <CheckCircleIcon className="w-4 h-4 text-primary" />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-sm text-gray font-normal font-helvetica">
                      Unlimited Listing
                    </td>
                    <td className="text-3xl">
                      <MinusIcon className="w-4 h-4 " />
                    </td>
                    <td>
                      <CheckCircleIcon className="w-4 h-4 text-primary" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Button
                title="Continue"
                full
                variant="primary"
                onClick={handleNavigate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
