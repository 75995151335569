export const Constant = {
  TOKEN: "accessToken",
  CURRENCY: "K",
  AUTHENTICATED: "authenticated",
  FURNISHING: [
    "Balkon",
    "Finanzierungsnachweis vorhanden",
    "Aufzug",
    "Energieeffizient",
    "Barrierefrei",
    "Parkplatz",
  ],
  AREA: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
    { label: "25", value: "25" },
    { label: "30", value: "30" },
    { label: "35", value: "35" },
  ],
  CONDITION: [
    { label: "New", value: "New" },
    { label: "Used", value: "Used" },
  ],
  ROOMS: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ],
  HOUSE_TYPE: [
    { label: "Burgen / Schlösser", value: "Burgen / Schlösser" },
    { label: "Bungalows", value: "Bungalows" },
    { label: "Bauernhäuser", value: "Bauernhäuser" },
    { label: "Dopplhäuser", value: "Dopplhäuser" },
    { label: "Doppelhaushälften", value: "Doppelhaushälften" },
    { label: "Einfamilienhäuser", value: "Einfamilienhäuser" },
    { label: "Fincas", value: "Fincas" },
    { label: "Gewerbeflächen", value: "Gewerbeflächen" },
    { label: "Herrenhäuser", value: "Herrenhäuser" },
    { label: "Mehrfamilienhäuser", value: "Mehrfamilienhäuser" },
    { label: "Reihenendhäuser", value: "Reihenendhäuser" },
    { label: "Reihenmittelhäuser", value: "Reihenmittelhäuser" },
    { label: "Rustico", value: "Rustico" },
    { label: "Stadthaus", value: "Stadthaus" },
    { label: "Villen", value: "Villen" },
    { label: "Zweifamilienhäuser", value: "Zweifamilienhäuser" },
  ],
  APARTMENT_TYPE: [
    { label: "Apartements", value: "Apartements" },
    { label: "Etagenwohnung", value: "Etagenwohnung" },
    { label: "Penthouse-Wohnungen", value: "Penthouse-Wohnungen" },
    { label: "Rohdachböden", value: "Rohdachböden" },
    { label: "Lofts", value: "Lofts" },
    { label: "Maisonettes", value: "Maisonettes" },
    { label: "Terrassenwohnungen", value: "Terrassenwohnungen" },
  ],
  CONSTRUCTION_YEAR: [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
  ],
  LAST_RENOVATION: [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
  ],
  CITY: [
    { label: "Berlin⁠", value: "Berlin⁠" },
    { label: "Hamburg⁠", value: "Hamburg⁠" },
    { label: "München", value: "München" },
    { label: "Bayern", value: "Bayern" },
    { label: "Köln⁠", value: "Köln⁠" },
    { label: "Frankfurt am Main", value: "Frankfurt am Main" },
    { label: "Stuttgart", value: "Stuttgart" },
    { label: "Düsseldorf", value: "Düsseldorf" },
  ],
  PRICE: [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" },
    { label: "400", value: "400" },
    { label: "500", value: "500" },
    { label: "1000", value: "1000" },
    { label: "2000", value: "2000" },
    { label: "3000", value: "3000" },
    { label: "4000", value: "4000" },
    { label: "5000", value: "5000" },
  ],
};
