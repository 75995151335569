import React, { useEffect, useState } from "react";

import { Footer, Navbar } from "../../../Components";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

export default function FaqPage() {
  const [open, setOpen] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] lg:px-10 sm:px-4 mb-10 flex flex-col gap-4 ">
        <p className="text-2xl font-helvetica font-semibold text-center">FAQ</p>
        <div>
          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(1)}
            >
              Is it free to use Sankapo.com?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              Yes, it is free to browse and place ads on Sankapo.com. However,
              there are optional paid services to promote your ads or feature
              them prominently on the site.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(2)}
            >
              How do I create an account on Sankapo.com?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              Visit www.sankapo.com and click on the "Register" button. Fill in
              the required personal information to create your free account.
              description, photos, price, and other relevant details.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(3)}
            >
              How do I place an ad on Sankapo.com?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              After logging in to your account, click on the "Place Ad" button
              and fill out the necessary information, including item
              description, photos, price, and other relevant details.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(4)}
            >
              How do I contact a seller on Sankapo.com?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              You can contact the seller directly through the Sankapo.com
              website by either sending them a message or by calling them (if
              their phone number is listed).
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(5)}
            >
              Does Sankapo.com handle payment and delivery?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              No, Sankapo.com does not handle payment or delivery directly.
              These arrangements are made between the buyer and seller.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(6)}
            >
              Can I use Sankapo.com if I'm not in Zambia?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              Yes, users from other countries can use the site and purchase
              items from Zambian sellers (and vice versa), but keep in mind that
              most listings and users are based in Zambia.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(7)}
            >
              Does Sankapo.com offer buyer protection or dispute resolution
              services?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              No, Sankapo.com does not offer any kind of buyer protection or
              dispute resolution service. Buyers and sellers are responsible for
              coming to an agreement and finalizing the transaction.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 8} icon={<Icon id={8} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(8)}
            >
              What items are prohibited on Sankapo.com?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              Illegal or restricted items, such as weapons, drugs, or stolen
              goods, are not allowed on Sankapo.com. You can report suspicious
              ads using the "Report Ad" button.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 9} icon={<Icon id={9} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(9)}
            >
              Is there a Sankapo.com mobile app?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              Yes, Sankapo.com offers a mobile app for added convenience. The
              app is available for download on both iOS and Android devices.
            </AccordionBody>
          </Accordion>
          <Accordion open={open === 10} icon={<Icon id={10} open={open} />}>
            <AccordionHeader
              className="font-helvetica"
              onClick={() => handleOpen(10)}
            >
              How can I receive notifications about new items or messages on
              Sankapo.com?
            </AccordionHeader>
            <AccordionBody className="font-helvetica text-lg">
              Sankapo.com offers a notification system to keep you informed
              about new items or messages related to your interests. You can
              enable notifications in your account settings.
            </AccordionBody>
          </Accordion>
        </div>
      </div>

      <Footer />
    </>
  );
}
