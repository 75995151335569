import * as React from "react";

function Box(props) {
  return (
    <svg width={20} height={20} fill="none" {...props}>
      <path
        d="M10 .38L.394 5.182v10.015L10 20l9.606-4.804V5.181L10 .381zM1.338 5.765l8.389-4.29V9.97l-2.502 1.28V4.9L3.907 6.52v6.429l-2.568 1.315v-8.5zm2.965 6.882V6.768l2.526-1.232v5.87l-2.526 1.242zM10 18.945l-8.55-4.276L10 10.393l8.55 4.275L10 18.945zm8.662-4.681L10.274 9.97V1.474l8.388 4.29v8.5z"
        fill="#073937"
      />
      <path
        d="M11.929 11.765l-1.962-.98-1.962.98 1.962.981 1.962-.98zM9.967 14.708l1.962-.98-1.962-.982-1.95.975-1.95-.975-1.963.981 1.962.98 1.95-.974 1.951.975zM9.967 14.708l-1.95.975-1.95-.975-1.963.98 1.962.981 1.95-.975 1.951.976 1.962-.981-1.962-.981zM9.967 18.632l1.962-.981-1.962-.98-1.962.98 1.962.98zM13.891 12.746l-1.962.981 1.962.98 1.962-.98-1.962-.98zM11.929 15.688l1.962.981 1.962-.98-1.962-.981-1.962.98zM4.882 9.715a.262.262 0 100-.525.262.262 0 000 .525zM15.492 6.371l-3.32-1.635v3.232l4.674 2.335v-3.27l-1.354-.662zM13.923 8.4V6.047l1.172.572v2.366L13.925 8.4zm-1.354-.677V5.37l.958.467v2.365l-.958-.479zm3.881 1.94l-.958-.48v-2.37l.958.467v2.382z"
        fill="#073937"
      />
    </svg>
  );
}

export default Box;
