import React, { useState } from "react";

import { useApp } from "../../Context";
import { useNavigate } from "react-router-dom";

import { useGetCategoryQuery } from "../../Services/CategoryService";
import { useGetBannersQuery } from "../../Services/CommonService";
import { useGetAllAdvertiseQuery } from "../../Services/AdvertiseService";

//import components
import {
  Footer,
  HomeCard,
  Navbar,
  Button,
  PreviewModal,
  SearchBar,
  Loader,
} from "../../Components";

//assets
import HeroImage from "../../Assets/newBg.png";
import Logo from "../../Assets/LOGO.png";

export default function HomePage() {
  const navigate = useNavigate();

  //services
  const { data: categories, isLoading: loadingCategory } =
    useGetCategoryQuery();
  const { data: banner, isLoading: loadingBanner } = useGetBannersQuery();
  const { data: advertise, isLoading: loadingAds } = useGetAllAdvertiseQuery();

  const [next, setNext] = useState(8);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedAdData, setSelectedAdData] = useState(null);

  const showMoreCities = () => {
    setNext((prevState) => prevState + 8);
  };

  const handleShowPreview = (adData) => {
    setSelectedAdData(adData);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setSelectedAdData(null);
    setShowPreview(false);
  };

  return (
    <>
      <Navbar />
      {loadingCategory ||
        loadingBanner ||
        (loadingAds && (
          <Loader
            isLoading={loadingCategory || loadingAds || loadingBanner}
            text="Loading..."
          />
        ))}
      {/* hero section */}
      <div className="mt-20 relative overflow-x-hidden">
        <img
          className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
          src={HeroImage}
          alt="hero-img"
        />
        <div className="!absolute inset-0 flex flex-col justify-center items-center text-center gap-10 md:px-20 px-5">
          <img
            className="object-cover h-12 object-center"
            src={Logo}
            alt="logo-img"
          />
          <p className="text-white font-helvetica md:text-[18px] text-md">
            Sankapo is the largest open marketplace for Zambian buyers <br />{" "}
            and sellers to list their products.
          </p>

          <div>
            <SearchBar categories={categories} />
          </div>
        </div>
        <div className="h-10 bg-secondary flex flex-row items-center justify-around">
          <p className="text-sm text-white font-helvetica">Secure payment</p>
          <p className="text-sm text-white font-helvetica">Verified sellers</p>
          <p className="text-sm text-white font-helvetica">
            Thousands of goods
          </p>
        </div>
      </div>

      {/* category section */}

      <div className="lg:mt-[87px] mt-[40px] px-4 lg:px-12 flex flex-col lg:gap-10 gap-5">
        <p className="md:text-[35px] text-2xl font-georgia ">
          Discover our categories
        </p>
        <div className="flex flex-row overflow-x-auto overflow-y-hidden lg:justify-between sm:gap-4">
          {categories?.map((item, idx) => (
            <a
              key={idx}
              className="flex flex-col items-center gap-4 transition ease-in-out delay-150  hover:scale-105 duration-300 lg:px-2 cursor-pointer"
              onClick={() => navigate(`/filter/${item.id}`)}
            >
              <div className="flex items-center justify-center lg:w-[70px] lg:h-[70px] w-[60px] h-[60px] shadow-md bg-white rounded-full">
                <img
                  className="object-cover lg:h-12 h-9 object-center"
                  src={`https://sankapo-backend.sankapo.com/uploads/icons/${item.icon}`}
                  alt="category-img"
                />
              </div>
              <p className="lg:text-lg text-sm font-helvetica text-gray">
                {item.category}
              </p>
            </a>
          ))}
        </div>
      </div>

      {/* place new ads here */}

      <div className="flex flex-col items-center justify-center mt-[40px] px-4 lg:px-12">
        {banner
          ?.filter((item) => item.title === "Sponsored ad")
          .map((item, idx) => (
            <img
              key={idx}
              className="object-cover w-full md:h-auto object-center"
              src={`https://sankapo-backend.sankapo.com/uploads/banners/${item.bannerImage}`}
              alt="banner-img"
            />
          ))}
      </div>

      {/* advertisement section */}
      <div className="mt-[51px] flex justify-center px-4 lg:px-12">
        <div className="grid grid-rows-1 md:grid-cols-4 gap-10">
          {advertise?.slice(0, next).map((item, idx) => (
            <HomeCard
              key={idx}
              data={item}
              onClick={() => handleShowPreview(item)}
            />
          ))}
        </div>
      </div>

      {advertise?.length > 8 && (
        <div className="flex justify-center mt-[41px] mb-10">
          <Button title="See More" variant="primary" onClick={showMoreCities} />
        </div>
      )}

      {/* client feedback section */}
      {/* <div className="mt-[20px]">
        <Feedback />
      </div> */}

      {/* <div className="mt-[50px] relative">
        <img
          className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
          src={BackgroundImage}
          alt="hero-img"
        />
        <div className="absolute top-[30%] left-20 md:w-1/4 w-3/4">
          <p className="md:text-[30px] text-xl font-georgia text-white ">
            Do you have something to sell ?
          </p>
          <p className="text-[18px] font-helvetica text-white font-light mt-[45px] mb-[20px]">
            Post your first ad and start making money
          </p>
          <Button
            title="Post your add free"
            variant="secondary"
            onClick={handleShowAdModal}
          />
        </div>
      </div> */}
      <Footer />
      {selectedAdData !== null && (
        <PreviewModal
          open={showPreview}
          handleClose={handleClosePreview}
          data={selectedAdData}
        />
      )}
    </>
  );
}
