import React from "react";
import { useNavigate } from "react-router-dom";

import { useApp } from "../../../../Context";

import LogoImg from "../../../../Assets/LOGO.png";
import Logo from "../../../../Assets/Logo";
import { TextInput } from "../../../../Components/FormElements";
import { Button } from "../../../../Components";

export default function StorePage() {
  const navigate = useNavigate();

  const { regData, setRegData } = useApp();

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("/payment");
  };

  //handle input values
  const handleInput = (e) => {
    const { name, value } = e.target;

    setRegData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="bg-secondary h-screen w-screen relative">
        <div className="absolute top-2 left-2">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="bg-white shadow-md rounded-xl lg:w-[470px] w-[350px] px-5 py-10">
            <div className="flex flex-col items-center gap-2 ">
              <Logo />
              <p className="lg:text-lg text-sm font-helvetica">Store</p>
              <p className="lg:text-lg text-sm font-helvetica text-gray text-center">
                Let's Get Your Store Up and Running!
              </p>
            </div>
            <form
              className="flex flex-col gap-y-5 mt-3"
              onSubmit={handleSubmit}
            >
              <TextInput
                label="Store logo"
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) =>
                  setRegData({ ...regData, store_logo: e.target.files[0] })
                }
                required
              />
              <TextInput
                name="store_name"
                value={regData.store_name}
                onChange={handleInput}
                placeholder="Enter your store name"
                label="Store Name"
                type="text"
                required
              />
              <TextInput
                name="store_location"
                value={regData.store_location}
                onChange={handleInput}
                placeholder="Enter your store address"
                label="Store Location"
                type="text"
                required
              />

              <Button title="Continue" full variant="primary" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
