import React, { useEffect } from "react";

import { useGetCategoryQuery } from "../../Services/CategoryService";

import { Footer, Loader, Navbar } from "../../Components";
import { Link } from "react-router-dom";

export default function CategoryPage() {
  //services
  const { data: categories, isLoading: loadingCategory } =
    useGetCategoryQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loadingCategory && (
        <Loader isLoading={loadingCategory} text="Please wait..." />
      )}
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] px-4 lg:px-12 flex flex-col lg:gap-10 gap-5 mb-10 lg:justify-center lg:items-center">
        <p className="md:text-[30px] font-semibold text-2xl font-georgia ">
          What do you want to advertise
        </p>

        <div className="grid lg:grid-cols-2 sm:grid-rows-1 lg:gap-6 sm:gap-3">
          {categories?.map((item, idx) => (
            <Link
              key={idx}
              to={`/create-advertisement/${item.id}`}
              className="flex items-center gap-4 bg-white shadow-md p-2 lg:w-[300px] cursor-pointer transition ease-in-out delay-150 hover:scale-105 duration-300 rounded-md"
            >
              <img
                className="object-cover lg:h-12 h-9 object-center"
                src={`https://sankapo-backend.sankapo.com/uploads/icons/${item.icon}`}
                alt="category-img"
              />
              <p className="lg:text-lg text-sm font-helvetica text-gray">
                {item.category}
              </p>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
