import * as React from "react";

function Logo(props) {
  return (
    <svg width={125} height={30} fill="none" {...props}>
      <path
        d="M1.003 9.09c2.081 1.145 4.484 1.395 6.822 1.318 2.049.004 4.297-.317 6.136.816 2.022 1.229 2.615 3.988 1.831 6.116-.695 1.855-2.687 3.048-4.633 3.035-3.72.014-7.442 0-11.159 0 .043-1.465.983-2.848 2.495-3.08 1.827-.275 6.006-.047 8.994-.287 1.766-.214 1.914-2.828.224-3.19-2.346-.391-4.76.166-7.095-.314-2.026-.52-3.381-2.39-3.615-4.415z"
        fill="#4182C3"
      />
      <path
        d="M4.4 3.966c1.32-.366 2.705-.266 4.061-.28 4.124.026 8.248-.033 12.375.033-.2.836-.28 1.804-.99 2.392-.919.81-2.208.883-3.37.905-3.221.007-6.442-.006-9.66-.006-.59.037-1.23-.014-1.766.277-.653.502-.59 1.412-.7 2.144-1.003-.706-2.511-1.129-2.865-2.425.228-1.467 1.524-2.637 2.916-3.04z"
        fill="#F88F21"
      />
      <path
        d="M52.805 13.975v8.665h-3.634v-8.15c0-2.24-1.12-3.525-3.074-3.525-2.126 0-3.394 1.473-3.394 3.94v7.736h-3.634V8.07h2.99v2.364c.88-1.91 3.26-2.632 5.16-2.622 3.551.001 5.586 2.246 5.586 6.164zM70.8 22.639h-4.661l-5.703-6.256v6.256h-3.634V.757h3.634v13.168l5.607-5.793h4.433L64.19 15.19l6.61 7.449zM117.255 7.813c-10.374-.102-10.369 15.249 0 15.146 10.326.102 10.327-15.25 0-15.146zm0 11.991c-5.497.062-5.497-8.903 0-8.84 5.448-.062 5.448 8.9 0 8.84zM31.843 8.069v1.794c-4.436-4.374-12.732-1.479-12.466 5.52-.405 6.552 7.92 10.117 12.466 5.486v1.768h2.99V8.07h-2.99zm-4.668 11.735c-5.493.06-5.492-8.902 0-8.84 5.453-.062 5.453 8.902 0 8.84zM84.218 8.069v1.795c-4.421-4.453-12.598-1.252-12.466 5.52-.356 6.405 7.925 10.166 12.466 5.485v1.768h2.99V8.07h-2.99zm-4.671 11.735c-5.493.06-5.492-8.902 0-8.84 5.453-.062 5.453 8.902 0 8.84z"
        fill="#4182C3"
      />
      <path
        d="M106.599 13.052l-.1-.344.073-.043-.079.027c-1.462-4.948-8.71-6.538-12.045-2.818V8.069h-2.991c-.005 4.253.009 16.607.01 20.663H95.1V21.47c3.899 3.212 10.368.854 11.491-3.727.422-1.472.427-3.227.007-4.691zm-4.713 5.716a3.51 3.51 0 01-.354.29v.014c-.106.048-.192.126-.299.183v.006c-2.865 1.604-6.352-.436-6.22-3.878 0-1.619.664-2.988 1.823-3.764 4.854-2.762 8.551 3.5 5.05 7.15z"
        fill="#4182C3"
      />
      <path
        d="M3.552 28.313H1.24l-.384.869h-.63L2.116 25h.573l1.896 4.182h-.65l-.383-.87zm-1.158-2.626l-.92 2.09h1.845l-.913-2.09h-.012zM7.657 25c1.392 0 2.217.78 2.217 2.083 0 1.317-.825 2.097-2.217 2.097h-1.63v-4.182h1.63v.001zm-.032 3.646c1.034 0 1.637-.574 1.637-1.561 0-.977-.605-1.549-1.637-1.549H6.63v3.111h.995v-.001zM13.059 25c1.392 0 2.217.78 2.217 2.083 0 1.317-.825 2.097-2.217 2.097h-1.63v-4.182h1.63v.001zm-.032 3.646c1.034 0 1.637-.574 1.637-1.561 0-.977-.605-1.549-1.637-1.549h-.995v3.111h.995v-.001zM19.052 25h.605v4.182h-.605V25zM23.127 29.182h-.605v-3.647h-1.379V25H24.5v.535h-1.373v3.647h.001zM27.942 27.538a.654.654 0 01.66-.662c.366 0 .662.29.662.656 0 .371-.289.662-.661.662a.654.654 0 01-.661-.656zM33.042 29.182V25h.605v3.646h2.369v.536h-2.974zM37.506 25h.605v4.182h-.605V25zM40.535 27.254v1.928h-.605V25h.605v1.895L42.501 25h.787l-2.186 2.06 2.249 2.122h-.819l-1.997-1.928zM47.399 26.8v.536h-2.003v1.31h2.455v.536h-3.06V25h3.06v.535h-2.455v1.266h2.003zM51.71 25h.605v4.182h-.605V25zM55.785 29.182h-.605v-3.647H53.8V25h3.356v.535h-1.373v3.647h.002zM60.6 27.538a.653.653 0 01.661-.662c.365 0 .662.29.662.656 0 .371-.29.662-.662.662a.653.653 0 01-.66-.656zM66.047 27.09c0 .984.673 1.619 1.687 1.619.353 0 .656-.057.92-.17v-.97h-1.209v-.536h1.789v1.807c-.397.277-.876.403-1.525.403-1.373 0-2.273-.844-2.273-2.153 0-1.31.9-2.154 2.273-2.154.65 0 1.128.126 1.525.403v.667h-.032c-.384-.365-.85-.536-1.468-.536-1.014.002-1.687.638-1.687 1.62zM73.578 26.8v.536h-2.004v1.31h2.456v.536h-3.06V25h3.06v.535h-2.456v1.266h2.004zM77.316 29.182h-.605v-3.647h-1.379V25h3.356v.535h-1.373v3.647h.001zM82.398 25h.605v4.182h-.605V25zM86.471 29.182h-.605v-3.647h-1.38V25h3.357v.535H86.47v3.647h.001z"
        fill="#F88F21"
      />
    </svg>
  );
}

export default Logo;
