import React, { useEffect } from "react";
import { Footer, Navbar } from "../../../Components";

export default function HowToPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] lg:px-10 sm:px-4 mb-10 flex flex-col gap-4 ">
        <p className="text-2xl font-helvetica font-semibold text-center">
          How to Buy
        </p>
        <p className="font-helvetica text-gray">
          Sankapo.com is a Zambian website for buying and selling second-hand
          items. Here is a description of the process for buying an item on the
          site,
        </p>
        <ul className="font-helvetica text-gray list-decimal pl-5">
          <li>
            Browse the website and search for the item you are interested in
            purchasing. You can narrow your search using filters such as
            location, price range, and category. Once you have found an item
            that you would like to buy, click on the listing to view the details
            and photos of the item.
          </li>
          <li>
            Once you have found an item that you would like to buy, click on the
            listing to view the details and photos of the item.
          </li>
          <li>
            The description of the item and check the condition, price, and
            location. If you have any questions, you can contact the seller by
            clicking on the "Contact" button.
          </li>
          <li>
            If you would like to proceed with the purchase, contact the seller
            directly to arrange payment and delivery or pickup of the item.
          </li>
          <li>
            Once you have entered your payment information and completed the
            checkout process, the seller will be notified of your purchase and
            will contact you to arrange the delivery or pickup of the item.
          </li>
          <li>
            After the item has been delivered or picked up, the transaction is
            complete. It is recommended that you leave feedback for the seller
            to help other users make informed decisions when buying from them in
            the future.
          </li>
        </ul>

        <p className="text-lg font-helvetica font-semibold">Buyer Agreement:</p>
        <ul className="font-helvetica text-gray list-decimal pl-5">
          <li>
            By using Sankapo.com to purchase items, you agree to the following
            terms and conditions: You acknowledge that Sankapo.com is not
            responsible for the quality, condition, or legality of any items
            sold on the site.
          </li>
          <li>
            You agree to communicate with the seller using the messaging feature
            on the site or through other means outside the platform, and to use
            a secure payment method when making a purchase.
          </li>
          <li>
            You understand that all transactions are between you and the seller,
            and that Sankapo.com is not responsible for any disputes or issues
            that may arise.
          </li>
          <li>
            You understand that all transactions are between you and the seller,
            and that Sankapo.com is not responsible for any disputes or issues
            that may arise.
          </li>
          <li>
            You agree to inspect the item carefully upon receipt and to contact
            the seller immediately if there are any discrepancies.
          </li>
          <li>
            You agree to leave feedback for the seller after the transaction is
            complete to help other users make informed decisions when buying
            from them in the future.
          </li>
          <li>
            By using Sankapo.com to purchase items, you agree to abide by these
            terms and conditions. Failure to do so may result in the suspension
            or termination of your account.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
