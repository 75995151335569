import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthHeaders } from "./baseQueries";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithAuthHeaders,
  tagTypes: ["User"],
  endpoints: (build) => ({
    getUser: build.query({
      query: ({ id }) => ({
        url: `user/profile/${id}`,
        method: "GET",
      }),
      providesTags: ["User"],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetUserQuery } = userApi;
