import React, { useState } from "react";
import { useMask } from "@react-input/mask";
import { Link, useParams, useNavigate } from "react-router-dom";

import { useAddAdvertiseMutation } from "../../Services/AdvertiseService";
import { useGetUserQuery } from "../../Services/UserService";
import { useApp } from "../../Context";

import { Alert, Button, Footer, Loader, Navbar } from "../../Components";
import { Checkbox, Dropdown, TextInput } from "../../Components/FormElements";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Constant } from "../../Constant";

export default function AddProductsPage() {
  const { category } = useParams();
  const navigate = useNavigate();

  //services
  const uId = localStorage.getItem("uId");

  const { data: user } = useGetUserQuery({
    id: uId,
  });
  const [addAdvertise, { isLoading }] = useAddAdvertiseMutation();
  const { advertiseData, setAdvertiseData } = useApp();

  const [imageIndex, setImageIndex] = useState(-1);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [error, setError] = useState({
    type: "",
    text: "",
    isShow: false,
  });

  const inputRefPhone = useMask({
    mask: "+260 (__) ___-__-__",
    replacement: { _: /\d/ },
  });

  const handleRemoveImages = () => {
    const updatedImages = Array.from(advertiseData.images).filter(
      (_, index) => index !== imageIndex
    );
    setAdvertiseData({ ...advertiseData, images: updatedImages });
  };

  //handle input values
  const handleInput = (e) => {
    const { name, value } = e.target;

    setAdvertiseData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();

      formData.append("title", advertiseData.title);
      formData.append("category", category);
      formData.append("description", advertiseData.description);
      Array.from(advertiseData.images).forEach((item) => {
        formData.append("images[]", item);
      });
      formData.append("condition", advertiseData.condition);
      formData.append("buy", advertiseData.price);
      formData.append("mobile", advertiseData.whatsapp_number);
      formData.append("landline", advertiseData.whatsapp_number);
      formData.append("email", user[0].email);
      formData.append("sellerName", user[0].fullname);
      formData.append("province", user[0].province);
      formData.append("city", user[0].city);
      formData.append("town", advertiseData.address);
      formData.append("owner", user[0].id);
      formData.append("address", advertiseData.address);

      const res = await addAdvertise(formData);

      if (res.error) {
        setError({
          type: "Warning",
          text: res.error.data.message,
          isShow: true,
        });
      }
      if (res.data) {
        setError({
          type: "Success",
          text: "Your ad will be reviewed by our SANKAPO team and you will be notified when it goes live, It can take up to 24hr",
          isShow: true,
        });
      }
    } catch (error) {
      setError({
        type: "Warning",
        text: "Something went wrong,Please try again later!",
        isShow: true,
      });
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} text="Please wait..." />
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] px-4 lg:px-12 flex flex-col lg:gap-5 gap-5 mb-10 lg:justify-center lg:items-center">
        <p className="md:text-[30px] text-2xl font-semibold font-georgia ">
          Create your new advertisement
        </p>
        <div className="lg:min-w-[800px] sm:min-w-full bg-white p-4 rounded-md ">
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <TextInput
              placeholder="Include keywords such as brand color, size etc."
              label="Title"
              name="title"
              value={advertiseData.title}
              onChange={handleInput}
              required
            />
            <TextInput
              placeholder="Give a brief description about what you are advertising"
              label="Description"
              name="description"
              value={advertiseData.description}
              onChange={handleInput}
              multiline
              required
            />
            <TextInput
              placeholder="Enter product location"
              label="Location"
              name="description"
              value={advertiseData.address}
              onChange={handleInput}
              required
            />
            <Dropdown
              data={Constant.CONDITION}
              placeholder="Select your item condition"
              label="Condition"
              name="condition"
              value={advertiseData.condition}
              onChange={handleInput}
              required
            />
            <div className="flex flex-col gap-2">
              <label
                className="text-[16px] font-helvetica font-bold "
                htmlFor="phoneNumber"
              >
                Whatsapp
              </label>
              <input
                ref={inputRefPhone}
                className="border border-1 border-gray1 p-2 rounded-md   text-[14px] focus:outline-none"
                type="text"
                name="whatsapp_number"
                value={advertiseData.whatsapp_number}
                onChange={handleInput}
                placeholder="Enter your whatsapp number"
                required
              />
            </div>
            <TextInput
              label="Upload images"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) =>
                setAdvertiseData({ ...advertiseData, images: e.target.files })
              }
              multiple
              required
            />
            {advertiseData.images.length > 0 && (
              <div className="transition-all duration-500 ease-in w-full flex flex-wrap gap-4 p-1">
                {Array.from(advertiseData.images).map((file, idx) => (
                  <div
                    key={idx}
                    className="w-20 h-20 p-1 bg-white border border-gray1 rounded-md relative"
                    onMouseMove={() => setImageIndex(idx)}
                    onMouseLeave={() => setImageIndex(-1)}
                  >
                    {imageIndex === idx && (
                      <a
                        className="bg-red-500 w-5 h-5 shadow-md absolute rounded-full flex justify-center items-center top-[-8px] right-[-8px] transition ease-in-out hover:scale-105 duration-300"
                        onClick={handleRemoveImages}
                      >
                        <XMarkIcon className="h-3 w-3 text-white" />
                      </a>
                    )}
                    <img
                      className="h-full  object-cover"
                      src={URL.createObjectURL(file)}
                    />
                  </div>
                ))}
              </div>
            )}
            <TextInput
              placeholder="0.00"
              label="Cost"
              type="number"
              name="price"
              value={advertiseData.price}
              onChange={handleInput}
              required
            />
            <span className="flex gap-2 text-sm font-helvetica text-gray">
              <Checkbox
                checked={agreeTerms}
                onClick={() => setAgreeTerms(!agreeTerms)}
              />
              <p>I agree to Sankapo’s </p>
              <Link to="/terms-and-condition" className="text-primary">
                Terms & Conditions
              </Link>
            </span>
            <div className="flex gap-6 mt-3">
              <Button type="submit" title="Continue" variant="primary" full />
              <Button
                title="Back"
                variant="secondary"
                full
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </div>
      <Alert
        isPopup={error.isShow}
        handlePopup={() => setError({ isShow: false })}
        type={error.type}
        text={error.text}
      />
      <Footer />
    </>
  );
}
