import * as React from "react";

function Image(props) {
  return (
    <svg width={26} height={20} fill="none" {...props}>
      <path
        d="M7.222 0C5.63 0 4.333 1.281 4.333 2.857v10c0 1.576 1.296 2.857 2.89 2.857H23.11c1.593 0 2.889-1.281 2.889-2.857v-10C26 1.281 24.704 0 23.111 0H7.222zm10.653 4.763l4.333 6.429c.222.33.244.75.055 1.098-.19.348-.556.567-.957.567H9.028c-.415 0-.795-.236-.975-.607a1.06 1.06 0 01.13-1.134l2.89-3.571a1.085 1.085 0 011.688 0l.78.964 2.528-3.75c.204-.295.542-.473.903-.473s.7.178.903.477zm-9.208-.477c0-.38.152-.743.423-1.01a1.453 1.453 0 012.043 0 1.42 1.42 0 010 2.02 1.453 1.453 0 01-2.043 0 1.42 1.42 0 01-.423-1.01zm-6.5-.357c0-.594-.483-1.072-1.084-1.072C.483 2.857 0 3.335 0 3.93v10C0 17.28 2.749 20 6.139 20h14.444c.6 0 1.084-.478 1.084-1.071 0-.594-.483-1.072-1.084-1.072H6.14c-2.194 0-3.972-1.759-3.972-3.928v-10z"
        fill="#073937"
      />
    </svg>
  );
}

export default Image;
