import * as React from "react";

function Right(props) {
  return (
    <svg width={12} height={10} fill="none" {...props}>
      <path
        d="M1.455 5.606L4.34 8.434 7.443 4.9l3.103-3.535"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Right;
