import React, { useEffect } from "react";
import { Footer, Navbar } from "../../../Components";

export default function VendorAgreementPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="lg:mt-[107px] mt-[90px] lg:px-10 sm:px-4 mb-10 flex flex-col gap-4 ">
        <p className="text-2xl font-helvetica font-semibold text-center">
          Vendor Agreement
        </p>
        <p className="font-helvetica text-gray">
          Thank you for choosing Sankapo as your online marketplace to sell your
          goods and services. This Vendor Agreement Policy outlines the terms
          and conditions that govern the relationship between Sankapo and you,
          the vendor. By using our platform, you agree to be bound by this
          policy.
        </p>

        <p className="text-lg font-helvetica font-semibold">Registration.</p>
        <p className="font-helvetica text-gray">
          To become a vendor on Sankapo.com, you must first register and create
          an account. During the registration process, you will be required to
          provide accurate and complete information about yourself and your
          business. You agree to keep this information up-to-date and accurate
          at all times.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Listing Requirements
        </p>
        <p className="font-helvetica text-gray">
          To list your products or services on Sankapo.com, you must ensure that
          they comply with our listing requirements. These include:
        </p>
        <ul className="list-disc font-helvetica text-gray pl-5">
          <li>Accurate and detailed product or service description</li>

          <li>Appropriate pricing for the product or service</li>

          <li>Up-to-date and clear images of the product or service</li>
        </ul>
        <p className="font-helvetica text-gray">
          You are responsible for ensuring that your listings are accurate and
          up-to-date. Sankapo reserves the right to remove any listing that does
          not comply with our policies without prior notice.
        </p>
        <p className="text-lg font-helvetica font-semibold">
          Fees and Payments
        </p>
        <p className="font-helvetica text-gray">
          As a vendor on Sankapo.com, you agree to pay the applicable fees for
          listing your products or services on our platform. These fees are
          subject to change from time to time, and you will be notified of any
          changes.
        </p>
        <p className="font-helvetica text-gray">
          When you list your products or services on Sankapo.com, buyers will
          pay you directly using the payment method of your choice. Sankapo does
          not process payments on your behalf. We only charge a minimal fee for
          listing your products or services on our platform, which starts at
          K15. This fee helps us maintain and improve our services for you.
          These fees are subject to change from time to time, and you will be
          notified of any changes. So when you sell on Sankapo, you can be sure
          that you will receive the full payment directly from the buyer,
          without any deductions from us.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Delivery and Returns
        </p>
        <p className="font-helvetica text-gray">
          As a vendor on Sankapo.com, you are responsible for delivering the
          products or services to the customer as agreed upon in the listing.
          You must ensure that the products or services are delivered in a
          timely and satisfactory manner.
        </p>
        <p className="font-helvetica text-gray">
          In case of a return or refund request from the customer, you are
          responsible for managing the return or refund process. Sankapo will
          not be liable for any returns or refunds.
        </p>
        <p className="text-lg font-helvetica font-semibold">
          Intellectual Property
        </p>
        <p className="font-helvetica text-gray">
          You agree that any content, including images and descriptions, that
          you post on Sankapo.com is your own or that you have the right to use.
          You also grant Sankapo a non-exclusive, royalty-free, transferable
          license to use, modify, and reproduce the content for the purposes of
          promoting and marketing your products or services on our platform.
        </p>
        <p className="text-lg font-helvetica font-semibold">
          Prohibited Activities
        </p>
        <p className="font-helvetica text-gray">
          As a vendor on Sankapo.com, you agree not to engage in any prohibited
          activities, including:
        </p>
        <ul className="list-disc font-helvetica text-gray pl-5">
          <li>Listing illegal or counterfeit products or services</li>
          <li>Engaging in fraudulent activities</li>
          <li>Violating any intellectual property rights</li>
          <li>Harassing or threatening other users</li>
          <li>Misrepresenting yourself or your products or services</li>
        </ul>
        <p className="font-helvetica text-gray">
          Sankapo reserves the right to terminate your account and/or remove
          your listings if you engage in any prohibited activities.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Limitation of Liability
        </p>
        <p className="font-helvetica text-gray">
          Sankapo will not be liable for any direct or indirect damages,
          including loss of profits or revenue, arising from your use of our
          platform. We also do not guarantee the quality, safety, or legality of
          the products or services listed on our platform.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Governing Law and Jurisdiction
        </p>
        <p className="font-helvetica text-gray">
          This Vendor Agreement Policy shall be governed by and construed in
          accordance with the laws of the country where Sankapo is registered.
          Any disputes arising from this policy will be subject to the exclusive
          jurisdiction of the courts in that country.
        </p>

        <p className="text-lg font-helvetica font-semibold">
          Changes to Policy.
        </p>
        <p className="font-helvetica text-gray">
          Sankapo reserves the right to modify, update or change this Vendor
          Agreement Policy at any time without prior notice. Any changes made
          will be effective immediately upon posting on the Sankapo website. By
          continuing to use the Sankapo platform, you accept and agree to any
          changes made to this policy.
        </p>
      </div>
      <Footer />
    </>
  );
}
