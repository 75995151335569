import React from "react";
import { Link } from "react-router-dom";

import LogoImg from "../Assets/LOGO.png";
import { FaceFrownIcon } from "@heroicons/react/24/outline";

export default function NotFoundPage() {
  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center h-screen relative bg-secondary ">
        <div className="absolute top-2 left-2 ">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div>
          <FaceFrownIcon className="h-12 w-12 text-white" />
        </div>
        <p className="text-3xl font-helvetica text-white">
          Opps... Page Not Found!
        </p>
        <p className="text-sm font-normal font-helvetica text-white">
          You can try retyping the url{" "}
        </p>
        <span className="text-sm font-normal font-helvetica text-white">
          Or you we can take you to the{"  "}
          <Link to="/" className="text-primary font-semibold">
            Sankapo homepage
          </Link>
        </span>
      </div>
    </>
  );
}
