import classNames from "classnames";

export const Button = ({ title, full, variant, ...rest }) => (
  <button
    className={classNames(
      "font-normal font-helvetica  text-[16px] px-8 py-3 rounded-md",
      variant == "primary" && "bg-primary text-white",
      variant === "secondary" && "bg-white text-primary border border-primary",
      full && "w-full"
    )}
    {...rest}
  >
    {title}
  </button>
);
