import * as React from "react";

function MapPin(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M15.75 7.5c0 5.25-6.75 9.75-6.75 9.75s-6.75-4.5-6.75-9.75a6.75 6.75 0 0113.5 0z"
        stroke="#073937"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.75a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
        stroke="#073937"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MapPin;
