import * as React from "react";

function RedClose(props) {
  return (
    <svg width={14} height={14} fill="none" {...props}>
      <path
        d="M12.33 1.668L1.665 12.335M1.664 1.668l10.667 10.667"
        stroke="#F04242"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RedClose;
