import React, { useRef, useState } from "react";
import axios from "axios";

import Magnifying from "../../Assets/Magnifying";

export const CountrySelect = ({
  name,
  label,
  selectedCountry,
  handleSelect,
  ...rest
}) => {
  const [result, setResult] = useState([]);
  const [query, setQuery] = useState("");
  const resultsRef = useRef(null);

  const handleSuggestCity = (e) => {
    const text = e.target.value;

    if (text) {
      setQuery(text);
      let config = {
        method: "get",
        url: `https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&apiKey=3ed82eac110f4fabbad64b85f8cbe6c2`,
        headers: {},
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          setResult(response.data.features);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setResult([]);
    }
  };

  const handleSelectCity = (city) => {
    setQuery(city);
    setResult([]);
    handleSelect(city);
  };

  return (
    <div className="flex flex-col gap-2 relative">
      <label
        className="text-[16px] font-helvetica font-bold text-primary"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="flex flex-row gap-1 px-2 items-center border border-1 border-gray1">
        <Magnifying />
        <input
          className="flex-1 p-2 rounded-sm bg-background placeholder:text-primary text-[14px] focus:outline-none"
          name={name}
          value={query}
          onChange={handleSuggestCity}
          {...rest}
        />
      </div>
      {result.length > 0 && (
        <div
          ref={resultsRef}
          className="absolute top-full left-0 right-0 flex flex-col gap-2 bg-background shadow-md rounded-sm p-2 z-10"
        >
          {result.map((item, idx) => (
            <a
              key={idx}
              className="text-sm font-helvetica cursor-pointer hover:bg-blue-gray-300 hover:text-white p-1 text-primary"
              onClick={() => handleSelectCity(item.properties.city)}
            >
              {item.properties.city}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
