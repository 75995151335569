import React from "react";

import { getFormattedBalance } from "../../Util/common";
import { Constant } from "../../Constant";

import Room from "../../Assets/Room";
import MapPin from "../../Assets/MapPin";

export const HomeCard = ({ data, ...rest }) => {
  let image;

  try {
    image = JSON.parse(data?.images);
  } catch (error) {
    console.log(error);
  }

  return (
    <a
      className="flex flex-col lg:w-72 w-full gap-2 shadow-md bg-white hover:bg-primary hover:text-white rounded-md overflow-hidden"
      {...rest}
    >
      <img
        src={`https://sankapo-backend.sankapo.com/uploads/products/${image[0]}`}
        alt="card-img"
        className="h-[200px] w-full object-contain"
      />
      <div className="p-2 ">
        <p className="text-lg font-bold uppercase font-helvetica">
          {Constant.CURRENCY} {getFormattedBalance(data?.buy)}
        </p>
        <p className="text-sm font-helvetica text-ellipsis">{data?.title}</p>
      </div>
    </a>
  );
};

export const DetailCard = ({ data, ...rest }) => {
  let image;

  try {
    image = JSON.parse(data?.images);
  } catch (error) {
    console.log(error);
  }

  return (
    <a
      className="flex lg:flex-row sm:flex-col gap-4 bg-white  shadow-md rounded-md lg:p-8 sm:p-4"
      {...rest}
    >
      <img
        src={`https://sankapo-backend.sankapo.com/uploads/products/${image[0]}`}
        alt="card-img"
        className="lg:w-[250px] sm:w-full h-[250px] object-cover"
      />

      <div className="flex flex-col gap-2">
        <p className="text-[16px] font-bold font-helvetica">{data.title}</p>

        <span className="flex items-center gap-2">
          <MapPin />
          <p className="text-[16px] font-light font-helvetica text-gray">
            {data.address}
          </p>
        </span>

        <p className="text-[16px] font-light font-helvetica text-gray text-ellipsis overflow-hidden ">
          {data.description}
        </p>
        <p className="text-[20px] font-bold font-helvetica text-secondary">
          {Constant.CURRENCY} {getFormattedBalance(data?.buy)}
        </p>
      </div>
    </a>
  );
};
