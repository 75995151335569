import React from "react";
import { useMask } from "@react-input/mask";
import { useNavigate } from "react-router-dom";

import { useApp } from "../../../../Context";

import LogoImg from "../../../../Assets/LOGO.png";
import Logo from "../../../../Assets/Logo";
import { TextInput } from "../../../../Components/FormElements";
import { Button } from "../../../../Components";

export default function StepTwoPage() {
  const navigate = useNavigate();

  const { regData, setRegData } = useApp();

  const inputRefPhone = useMask({
    mask: "+260 (__) ___-__-__",
    replacement: { _: /\d/ },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/register-account");
  };

  //handle input values
  const handleInput = (e) => {
    const { name, value } = e.target;

    setRegData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="bg-secondary h-screen w-screen relative">
        <div className="absolute top-2 left-2">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="bg-white shadow-md rounded-xl lg:w-[470px] w-[350px] px-5 py-10">
            <div className="flex flex-col items-center gap-2 ">
              <Logo />
              <p className="lg:text-lg text-sm font-helvetica">Sign up</p>
              <p className="lg:text-lg text-sm font-helvetica text-gray text-center">
                Sign up and open doors to endless shopping
              </p>
            </div>
            <form
              className="flex flex-col gap-y-5 mt-3"
              onSubmit={handleSubmit}
            >
              <TextInput
                name="full_name"
                value={regData.full_name}
                onChange={handleInput}
                placeholder="Enter your full name"
                label="Full Name"
                type="text"
                required
              />
              <TextInput
                name="email"
                value={regData.email}
                onChange={handleInput}
                placeholder="Enter your email"
                label="Email"
                type="email"
                required
              />

              <div className="flex flex-col gap-2">
                <label
                  className="text-[16px] font-helvetica font-bold "
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </label>
                <input
                  ref={inputRefPhone}
                  name="phone_number"
                  value={regData.phone_number}
                  onChange={handleInput}
                  className="border border-1 border-gray1 p-2 rounded-md   text-[14px] focus:outline-none"
                  type="text"
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              <TextInput
                name="password"
                value={regData.password}
                onChange={handleInput}
                placeholder="Enter your password"
                label="Password"
                type="password"
                minLength={6}
                required
              />

              <Button title="Continue" full variant="primary" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
