import React from "react";
import classNames from "classnames";

import {
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

export const Alert = ({ type, isPopup = false, handlePopup, text }) => {
  if (isPopup) {
    setTimeout(function () {
      handlePopup();
    }, 4000);
  }

  return (
    <>
      <div
        className={classNames(
          "fixed top-2 bg-white border right-4 flex max-w-[260px] min-w-[260px] p-3 text-black rounded opacity-0 invisible z-[60] transition-all duration-300",
          isPopup && "!visible opacity-100",
          type === "Success"
            ? "border-green-300"
            : type === "Warning"
            ? "border-red-300"
            : type === "Info"
            ? "border-blue-300"
            : "border-black"
        )}
      >
        {type === "Success" ? (
          <CheckCircleIcon className="h-8 w-8 mr-2 text-green-300" />
        ) : type === "Warning" ? (
          <ExclamationCircleIcon className="h-8 w-8 mr-2 text-red-300" />
        ) : type === "Info" ? (
          <InformationCircleIcon className="h-8 w-8 mr-2 text-blue-300" />
        ) : (
          <InformationCircleIcon className="h-8 w-8 mr-2 text-black" />
        )}
        <div className="flex flex-col">
          <h3 className="font-semibold">{type}</h3>
          <p className="text-sm text-[#636868]">{text}</p>
        </div>
      </div>
    </>
  );
};
