import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useLoginMutation } from "../../../Services/AuthService";

import { Checkbox, TextInput } from "../../../Components/FormElements";
import { Alert, Button, Loader } from "../../../Components";

import LogoImg from "../../../Assets/LOGO.png";
import Logo from "../../../Assets/Logo";

export default function LoginPage() {
  const navigate = useNavigate();
  //services
  const [login, { isLoading }] = useLoginMutation();

  const [state, setState] = useState({
    email: null,
    password: null,
  });
  const [error, setError] = useState({
    type: "",
    text: "",
    isShow: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await login(state);

      if (res.error) {
        setError({
          type: "Warning",
          text: res.error.data.message,
          isShow: true,
        });
      }
      if (res.data) {
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem("uId", res.data.user.id);
        navigate("/");
      }
    } catch (error) {
      setError({
        type: "Warning",
        text: "Something went wrong,Please try again later!",
        isShow: true,
      });
    }
  };
  return (
    <>
      <Loader isLoading={isLoading} text="Please wait..." />
      <div className="bg-secondary h-screen w-screen relative">
        <div className="absolute top-2 left-2">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="bg-white shadow-md rounded-xl lg:w-[470px] w-[350px] px-5 py-10">
            <div className="flex flex-col items-center gap-3 ">
              <Logo />
              <p className="lg:text-lg text-sm font-helvetica">
                Login to your account
              </p>
              <p className="lg:text-lg text-sm font-helvetica text-gray text-center">
                Welcome back! Please enter your details
              </p>
            </div>
            <form
              className="flex flex-col gap-y-5 mt-3"
              onSubmit={handleSubmit}
            >
              <TextInput
                placeholder="Enter your email"
                label="Email"
                required
                onChange={(e) =>
                  setState((prev) => ({ ...prev, email: e.target.value }))
                }
              />
              <TextInput
                placeholder="Enter your password"
                label="Password"
                type="password"
                required
                onChange={(e) =>
                  setState((prev) => ({ ...prev, password: e.target.value }))
                }
              />
              <div className="flex justify-between">
                <Checkbox label="Remember me" />
                <a className="text-primary font-helvetica font-bold" href="">
                  Forgot password
                </a>
              </div>
              <Button title="Login" full variant="primary" type="submit" />
              <span className="text-gray font-helvetica">
                Do not have an account?{" "}
                <Link to="/account-type" className="text-primary font-bold">
                  Register here
                </Link>
              </span>
            </form>
          </div>
        </div>
      </div>
      <Alert
        isPopup={error.isShow}
        handlePopup={() => setError({ isShow: false })}
        type={error.type}
        text={error.text}
      />
    </>
  );
}
