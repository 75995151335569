import * as React from "react";

function Magnifying(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_75_174)">
        <path
          d="M15.975 18a.953.953 0 01-.682-.285l-5.588-5.602.533-.533 5.587 5.61a.24.24 0 00.15.06.21.21 0 00.15-.06l1.065-1.065a.225.225 0 000-.307l-5.625-5.648.525-.525 5.633 5.64a.967.967 0 010 1.365l-1.066 1.065a.93.93 0 01-.682.285zM6 0a6 6 0 100 12A6 6 0 006 0zm0 11.25A5.25 5.25 0 116 .75a5.25 5.25 0 010 10.5z"
          fill="#073937"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_75_174">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Magnifying;
