import * as React from "react";

function DocumentIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <g
        clipPath="url(#prefix__clip0_2001_1643)"
        stroke="#073937"
        strokeWidth={1.5}
      >
        <path d="M3.75 6c0-2.121 0-3.182.659-3.841C5.068 1.5 6.129 1.5 8.25 1.5h1.5c2.121 0 3.182 0 3.841.659.659.659.659 1.72.659 3.841v6c0 2.121 0 3.182-.659 3.841-.659.659-1.72.659-3.841.659h-1.5c-2.121 0-3.182 0-3.841-.659-.659-.659-.659-1.72-.659-3.841V6z" />
        <path
          d="M6.75 9.75h4.5M6.75 6.75h4.5M6.75 12.75H9M1.5 14.25V3.75M16.5 14.25V3.75"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_2001_1643">
          <rect width={18} height={18} rx={5} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DocumentIcon;
