import React, { useState } from "react";
import { useMask } from "@react-input/mask";
import { useNavigate } from "react-router-dom";

import { useApp } from "../../../../Context";

import { TextInput } from "../../../../Components/FormElements";
import { Alert, Button, Loader } from "../../../../Components";

import LogoImg from "../../../../Assets/LOGO.png";
import Logo from "../../../../Assets/Logo";
import { useRegisterMutation } from "../../../../Services/AuthService";

export default function StepThreePage() {
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();

  const { regData, setRegData } = useApp();

  const [error, setError] = useState({
    type: "",
    text: "",
    isShow: false,
  });

  const inputRefNrc = useMask({
    mask: "______/__/_",
    replacement: { _: /\d/ },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (regData.accountType === "Free") {
      try {
        let formData = new FormData();

        formData.append("fullname", regData.full_name);
        formData.append("email", regData.email);
        formData.append("phonenumber", regData.phone_number);
        formData.append("nrc", regData.nrc);
        formData.append("password", regData.password);
        formData.append("province", regData.province);
        formData.append("city", regData.city);
        formData.append("postal_code", regData.postal_code);
        formData.append("town", regData.street_address);
        formData.append("dob", "19980124");
        formData.append("role", regData.accountType === "Free" ? 0 : 1);
        formData.append("image", regData.image);

        const res = await register(formData);

        if (res.error) {
          setError({
            type: "Warning",
            text: res.error.data.message,
            isShow: true,
          });
        }

        if (res.data) {
          console.log("res", res);
          localStorage.setItem("accessToken", res.data.data.token);
          localStorage.setItem("uId", res.data.data.userData.id);
          setError({
            type: "Success",
            text: "Account created successfully!",
            isShow: true,
          });
          setTimeout(function () {
            navigate("/");
          }, 4000);
        }
      } catch (error) {
        console.log(error);
        setError({
          type: "Warning",
          text: "Something went wrong, please try again later!",
          isShow: true,
        });
      }
    } else {
      navigate("/store");
    }
  };

  //handle input values
  const handleInput = (e) => {
    const { name, value } = e.target;

    setRegData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Loader isLoading={isLoading} text="Please wait..." />
      <div className="bg-secondary h-screen w-screen relative">
        <div className="absolute top-2 left-2">
          <img src={LogoImg} className="lg:h-10 sm:h-6 w-auto object-cover" />
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="bg-white shadow-md rounded-xl lg:w-[470px] w-[350px] px-5 py-10">
            <div className="flex flex-col items-center gap-2 ">
              <Logo />
              <p className="lg:text-lg text-sm font-helvetica">Sign up</p>
              <p className="lg:text-lg text-sm font-helvetica text-gray text-center">
                Sign up and open doors to endless shopping
              </p>
            </div>
            <form
              className="flex flex-col gap-y-5 mt-3"
              onSubmit={handleSubmit}
            >
              <TextInput
                name="street_address"
                value={regData.street_address}
                onChange={handleInput}
                placeholder="Enter your address"
                label="Address"
                type="email"
                multiline
                required
              />

              <div className="flex flex-col gap-2">
                <label
                  className="text-[16px] font-helvetica font-bold "
                  htmlFor="nrcNumber"
                >
                  NRC Number
                </label>
                <input
                  ref={inputRefNrc}
                  name="nrc"
                  value={regData.nrc}
                  onChange={handleInput}
                  className="border border-1 border-gray1 p-2 rounded-md  text-[14px] focus:outline-none"
                  type="text"
                  placeholder="Enter your nrc number"
                  required
                />
              </div>

              <TextInput
                label="Profile image"
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) =>
                  setRegData({ ...regData, image: e.target.files[0] })
                }
                required
              />

              <Button title="Continue" full variant="primary" />
            </form>
          </div>
        </div>
      </div>
      <Alert
        isPopup={error.isShow}
        handlePopup={() => setError({ isShow: false })}
        type={error.type}
        text={error.text}
      />
    </>
  );
}
