import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: publicBaseQuery,
  tagTypes: ["Category"],
  endpoints: (build) => ({
    getCategory: build.query({
      query: () => ({
        url: "/category/list",
        method: "GET",
      }),
      providesTags: ["Category"],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetCategoryQuery } = categoryApi;
